import axios, { HttpStatusCode, InternalAxiosRequestConfig } from 'axios';
import { API_URL } from 'config';
import { toast } from 'react-toastify';
import history from 'router/history';
import {
  LoginResponse,
  LoginResponseSchema,
  RefreshTokenRequest,
} from 'types/auth';

export const client = axios.create({
  baseURL: API_URL,
});

function authRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
  const tokenString = localStorage.getItem('token');
  //TODO: try catch this
  try {
    const tokenObject: LoginResponse = JSON.parse(tokenString);

    config.headers.Accept = 'application/json';

    if (tokenObject) {
      const token: LoginResponse = LoginResponseSchema.parse(tokenObject);
      if (token?.expiresAt > new Date()) {
        config.headers.Authorization = `Bearer ${token.accessToken}`;
      }
    }
  } catch (error) {
    console.log(`The stored token wasn't our: ${tokenString}`);
  }

  return config;
}

client.interceptors.request.use(authRequestInterceptor);
client.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (HttpStatusCode.Unauthorized === error.response.status) {
      //If we were calling the refreshtoken, there we logout
      if (error?.config?.url.includes('/auth/refreshtoken')) {
        localStorage.removeItem('token');
        if (!history.location?.pathname.startsWith('/login')) {
          history.replace('/logout');
        }
        return Promise.reject(error);
      }

      //Try to use our refreshtoken to get a new accessToken
      const originalRequest = error.config;

      originalRequest._retry = true;

      try {
        const tokenString = localStorage.getItem('token');

        const token: LoginResponse = JSON.parse(tokenString);

        const request: RefreshTokenRequest = {
          refreshToken: token.refreshToken,
        };

        const refreshTokenResponse: LoginResponse = LoginResponseSchema.parse(
          await client.post(`/auth/refreshtoken`, request)
        );

        localStorage.setItem('token', JSON.stringify(refreshTokenResponse));

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${refreshTokenResponse.accessToken}`;
        return (await axios(originalRequest)).data;
      } catch (error) {
        localStorage.removeItem('token');
      }
      if (!history.location?.pathname.startsWith('/login')) {
        history.replace('/logout');
        //We didn't managed to use the refreshToken, so the user should log again
        toast.error('Veuillez vous reconnecter.', {
          toastId: 'unauthorizeToast',
        });
      }

      return Promise.reject(error);
    }

    if (HttpStatusCode.Forbidden === error.response.status) {
      toast.error(
        'Action interdite. Veuillez contacter notre service client.',
        { toastId: 'forbiddenToast' }
      );
    }

    if (HttpStatusCode.InternalServerError === error.response.status) {
      toast.error('Erreur interne. Veuillez réessayer plus tard.', {
        toastId: 'internalServerErrorToast',
      });
    }

    return Promise.reject(error);
  }
);
