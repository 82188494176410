// @ts-nocheck
import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import AuthGuard from './Guards/AuthGuard';
import GuestGuard from './Guards/GuestGuard';
import Loadable from './Loadable';
import Logout from 'pages/Logout';

const Login = Loadable(lazy(() => import('../pages/login')));
const Profile = Loadable(lazy(() => import('../pages/Profile')));
const Project = Loadable(lazy(() => import('../pages/Project')));
const Search = Loadable(lazy(() => import('../pages/Search')));
const MyAccount = Loadable(lazy(() => import('../pages/MyAccount')));
const NotFound = Loadable(lazy(() => import('../pages/NotFound')));
const Request = Loadable(lazy(() => import('../pages/Request')));
const Result = Loadable(lazy(() => import('../pages/Result')));
const RenewPasswordQuery = Loadable(
  lazy(() => import('../pages/RenewPasswordQuery'))
);
const RenewPassword = Loadable(lazy(() => import('../pages/RenewPassword')));
const NavigateToFirstRequest = Loadable(
  lazy(() => import('../components/generic/NavigateToFirstRequest'))
);
export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <Profile />
        </AuthGuard>
      ),
    },
    {
      path: '/login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '/renewpasswordquery',
      element: (
        <GuestGuard>
          <RenewPasswordQuery />
        </GuestGuard>
      ),
    },
    {
      path: '/renewpassword',
      element: (
        <GuestGuard>
          <RenewPassword />
        </GuestGuard>
      ),
    },
    {
      path: '/profile',
      element: (
        <AuthGuard>
          <Profile />
        </AuthGuard>
      ),
    },
    {
      path: '/project/:projectId',
      element: (
        <AuthGuard>
          <Project />
        </AuthGuard>
      ),
    },
    {
      path: '/project/:projectId/first',
      element: (
        <AuthGuard>
          <NavigateToFirstRequest />
        </AuthGuard>
      ),
    },
    {
      path: '/account',
      element: (
        <AuthGuard>
          <MyAccount />
        </AuthGuard>
      ),
    },
    {
      path: '/project/:projectId/request/:requestId/:update',
      element: (
        <AuthGuard>
          <Request />
        </AuthGuard>
      ),
    },
    {
      path: '/project/:projectId/request/:requestId/search/:jobId',
      element: (
        <AuthGuard>
          <Search />
        </AuthGuard>
      ),
    },
    {
      path: '/project/:projectId/request/:requestId/result/:resultId',
      element: (
        <AuthGuard>
          <Result />
        </AuthGuard>
      ),
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <NotFound />
        </AuthGuard>
      ),
    },
  ]);
}
