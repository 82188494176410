import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoginResponse } from 'types/auth';

interface LoginState {
  isConnected: boolean;
  userToken: LoginResponse;
}

const initialState: LoginState = {
  isConnected: false,
  userToken: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    startSession: (state, action: PayloadAction<LoginResponse>) => {
      state.isConnected = true;
      state.userToken = action.payload;
    },
    stopSession: (state) => {
      state.isConnected = false;
      state.userToken = null;
    },
  },
});
export const { startSession, stopSession } = loginSlice.actions;
export default loginSlice.reducer;
