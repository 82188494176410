import { z } from 'zod';

export const UserInfoSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  creationDate: z.coerce.date(),
  validUntil: z.coerce.date(),
});

export type UserInfo = z.infer<typeof UserInfoSchema>;
