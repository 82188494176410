import React from 'react';
import '../../scss/components/header.scss';
import GoHome from '../header/GoHome';
import logo from '../../assets/icons/logo-white.png';
import UserInfo from 'components/header/UserInfo';

function Header() {
  return (
    <header className="app-header">
      <div className="app-header_content">
        <div className="logo-container">
          <img src={logo} alt="GraphMyTech" />
        </div>

        <GoHome />
        <UserInfo />
      </div>
    </header>
  );
}

export default Header;
