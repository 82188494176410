import React from 'react';

import { useNavigate } from 'react-router-dom';
import homeIcon from '../../assets/icons/homeIcon.svg';

function GoHome() {
  const navigate = useNavigate();
  function handleNavigateHome(e) {
    e.preventDefault();
    navigate('/profile');
  }

  return (
    <button onClick={handleNavigateHome} className="goHomeBtn" type="button">
      <img src={homeIcon} alt="retourner à l'accueil" />
    </button>
  );
}
export default GoHome;
