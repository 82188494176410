export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const PDF_MAX_SIZE = Number(process.env.REACT_APP_PDF_MAX_SIZE);
export const PDF_START_DATE = new Date(process.env.REACT_APP_PDF_START_DATE);
export const PDF_START_DEFAULT_DATE = new Date(
  process.env.REACT_APP_PDF_START_DATE_DEFAULT
);
export const PDF_END_DATE = new Date(process.env.REACT_APP_PDF_END_DATE);
export const PDF_END_DEFAULT_DATE = new Date(
  process.env.REACT_APP_PDF_END_DATE_DEFAULT
);
export const REQUEST_INPUT_TEXT_MINIMUM_LENGTH = Number(
  process.env.REACT_APP_REQUEST_INPUT_TEXT_MINIMUM_LENGTH
);
