import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Navigate } from 'react-router-dom';
import { startSession } from 'redux/features/loginSlice';
import { LoginResponse } from 'types/auth';

interface GuestGuardProps {
  children: React.ReactNode;
}

function GuestGuard({ children }: GuestGuardProps) {
  const dispatch = useAppDispatch();
  const isLogged = useAppSelector((state) => state.login.isConnected);

  const searchParams = new URLSearchParams(document.location.search);
  const redirectTo = searchParams.get('redirectTo');
  const tokenString = localStorage.getItem('token');

  //TODO: try catch this
  const token: LoginResponse = JSON.parse(tokenString);

  if (token) {
    dispatch(startSession(token));
    return <Navigate to={redirectTo || '/'} />;
  }

  if (isLogged) {
    return <Navigate to={redirectTo || '/'} />;
  }

  return children;
}

export default GuestGuard;
