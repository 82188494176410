import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface AppSliceState {
  resultsGraphDrawerIsOpen: boolean;
}

const initialState: AppSliceState = {
  resultsGraphDrawerIsOpen: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setResultsGraphDrawerOpen: (
      state,
      {
        payload,
      }: {
        payload: boolean;
      }
    ) => {
      state.resultsGraphDrawerIsOpen = payload;
    },
  },
});

export const appSelectors = {
  getResultsGraphDrawerIsOpen: (state: RootState) =>
    state.app.resultsGraphDrawerIsOpen,
};

export const appActions = appSlice.actions;
export default appSlice.reducer;
