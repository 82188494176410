import React, { useState, useRef, useEffect } from 'react';
import profilePicture from '../../assets/icons/man-shape.png';
import arrowBottom from '../../assets/icons/arrow-bottom-white.svg';
import NavigationMenu from './NavigationMenu';
import { useUserInfo } from 'api/user';
import { jwtDecode } from 'jwt-decode';
import { useAppSelector } from 'hooks';
import { PulseLoader } from 'react-spinners';
import { HttpStatusCode } from 'axios';
import { toast } from 'react-toastify';

function UserInfo() {
  const userToken = useAppSelector((state) => state.login.userToken);
  const jwt = jwtDecode(userToken?.accessToken);
  const userInfoQuery = useUserInfo({ userId: jwt?.sub });

  const [openMenu, setOpenMenu] = useState(false);

  const userInfoRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (userInfoRef.current && !userInfoRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [userInfoRef]);

  const showMenu = (e) => {
    e.preventDefault();
    if (openMenu === true) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  };

  if (userInfoQuery.isPending) {
    return <PulseLoader color="#4A90E2" size={10} />;
  }

  if (userInfoQuery.isError) {
    if (HttpStatusCode.NotFound === userInfoQuery.error?.response?.status) {
      toast.error(
        'Utilisateur inconnu. Veuillez contacter notre service client.',
        {
          toastId: 'userInfoQuery.NotFound',
        }
      );
    }
    return <PulseLoader color="#4A90E2" size={10} />;
  }

  return (
    <div
      className="userInfo"
      ref={userInfoRef}
      onClick={showMenu}
      onKeyDown={showMenu}
    >
      <p className="user_name">{userInfoQuery?.data.firstName}</p>
      <div className="profil_picture">
        <img src={profilePicture} alt="Profile" />
      </div>
      <img className="dropdown-btn" src={arrowBottom} alt="ouvrir le menu" />
      {openMenu ? <NavigationMenu /> : null}
    </div>
  );
}
export default UserInfo;
