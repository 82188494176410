import MoonLoader from 'react-spinners/MoonLoader';

export default function FullScreenSpinner() {
  return (
    <main className="profile-content h-screen justify-center items-center">
      <div className="loader">
        <MoonLoader color="#193DCD" size={150} />
      </div>
    </main>
  );
}
