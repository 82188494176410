import { z } from 'zod';

export type LoginRequest = {
  login: string;
  password: string;
  forceLogout: boolean;
};

export const LoginResponseSchema = z.object({
  accessToken: z.string(),
  tokenType: z.string(),
  refreshToken: z.string(),
  expiresAt: z.coerce.date(),
});

export type LoginResponse = z.infer<typeof LoginResponseSchema>;

export type RefreshTokenRequest = {
  refreshToken: string;
};

export type QueryRenewPasswordRequest = {
  email: string;
};

export type RenewPasswordRequest = {
  tokenValue: string;
  newPassword: string;
};
