import React from 'react';
import '../scss/App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppSelector } from 'hooks';
import Header from './layout/header';
import Router from '../router/router';

// style
import '../scss/bases/typographies.scss';
import '../scss/bases/bases.scss';

function App() {
  const isLogged = useAppSelector((state) => state.login.isConnected);

  return (
    <div className="App">
      {isLogged ? <Header /> : null}

      <Router />
      <ToastContainer />
    </div>
  );
}

export default App;
