import { useLogout } from 'api/auth';
import FullScreenSpinner from 'components/generic/FullScreenSpinner';
import { useAppDispatch } from 'hooks';
import { queryClient } from 'lib/react-query';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { stopSession } from 'redux/features/loginSlice';
import { cleanUserProfile } from 'redux/features/userProfile';

function Logout() {
  const dispatch = useAppDispatch();
  const logoutMutation = useLogout();

  useEffect(() => {
    logoutMutation.mutateAsync({});
    dispatch(stopSession());
    dispatch(cleanUserProfile());
    queryClient.removeQueries();
  }, []);

  if (logoutMutation.isError || logoutMutation.isSuccess) {
    return <Navigate to="/login" />;
  }

  return <FullScreenSpinner />;
}
export default Logout;
