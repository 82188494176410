import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserProfileState {
  email: string;
  prenom: string;
  nom: string;
  creation: string;
  validite: string;
}

const initialState: UserProfileState = {
  email: '',
  prenom: '',
  nom: '',
  creation: '',
  validite: '',
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: initialState,
  reducers: {
    getUserInfos: (state, action: PayloadAction<UserProfileState>) => {
      state.email = action.payload.email;
      state.prenom = action.payload.prenom;
      state.nom = action.payload.nom;
      state.creation = action.payload.creation;
      state.validite = action.payload.validite;
    },
    cleanUserProfile: (state) => {
      state.email = '';
      state.prenom = '';
      state.nom = '';
      state.creation = '';
      state.validite = '';
    },
  },
});
export const { getUserInfos, cleanUserProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
