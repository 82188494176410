import React from 'react';
import { useAppSelector } from 'hooks';
import { Navigate, useLocation } from 'react-router-dom';

interface AuthGuardProps {
  children: React.ReactNode;
}

function AuthGuard({ children }: AuthGuardProps) {
  const isLogged = useAppSelector((state) => state.login.isConnected);
  const { pathname } = useLocation();

  if (!isLogged) {
    return <Navigate to={`/login?redirectTo=${pathname}`} />;
  }

  return children;
}

export default AuthGuard;
