/* eslint-disable react/display-name */
/* eslint-disable func-names */
import React, { Suspense } from 'react';

const Loadable = (Component) =>
  function (props) {
    return (
      <Suspense fallback={<div>Chargement</div>}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
