import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { UserInfoSchema, UserInfo } from 'types/user';
import { client } from 'utils/axios.config';

export const getUserInfo = async (userId: string): Promise<UserInfo> => {
  return UserInfoSchema.parse(await client.get(`/api/user/${userId}`));
};

type QueryFnTypeGetUserInfo = typeof getUserInfo;

type UseUserInfoOptions = {
  userId: string;
  config?: QueryConfig<QueryFnTypeGetUserInfo>;
};

export const useUserInfo = ({ userId, config = {} }: UseUserInfoOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnTypeGetUserInfo>>({
    ...config,
    staleTime: 60 * 60 * 1000, //not refetching for the next hour
    queryKey: ['user'],
    queryFn: () => getUserInfo(userId),
  });
};
