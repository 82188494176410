import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function NavigationMenu() {
  const navigate = useNavigate();

  const logOut = () => {
    navigate('/logout');
  };

  return (
    <div className="navigation-menu">
      <Link className="my-account" to="/account">
        Mon compte
      </Link>
      <button onClick={logOut} className="logout" type="button">
        Déconnexion
      </button>
    </div>
  );
}

export default NavigationMenu;
