import { MutationConfig } from 'lib/react-query';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  LoginRequest,
  LoginResponse,
  LoginResponseSchema,
  QueryRenewPasswordRequest,
  RenewPasswordRequest,
} from 'types/auth';
import { client } from 'utils/axios.config';
import history from 'router/history';
import { HttpStatusCode } from 'axios';

export const login = async ({
  login,
  password,
  forceLogout,
}: {
  login: string;
  password: string;
  forceLogout: boolean;
}): Promise<LoginResponse> => {
  const request: LoginRequest = {
    login: login,
    password,
    forceLogout,
  };
  return LoginResponseSchema.parse(await client.post(`/auth/login`, request));
};

type UseLoginOptions = {
  config?: MutationConfig<typeof login>;
};

export const useLogin = ({ config }: UseLoginOptions = {}) => {
  return useMutation({
    onError: (apiError, __, context: any) => {
      if (HttpStatusCode.Unauthorized === apiError?.response?.status) {
        toast.error('Connexion impossible. Veuillez réessayer plus tard.', {
          toastId: 'unauthorizeToast',
        });
      }
      if (HttpStatusCode.Forbidden === apiError?.response?.status) {
        toast.error(
          'Connexion impossible. Veuillez contacter notre service client.',
          {
            toastId: 'forbiddenToast',
          }
        );
      }
      if (HttpStatusCode.Locked === apiError?.response?.status) {
        toast.error(
          'Votre compte est actuellement bloqué car trop de login ont été tentés sans succès.',
          {
            toastId: 'useLogin.Locked',
          }
        );
      }
      if (HttpStatusCode.TooEarly === apiError?.response?.status) {
        toast.error('Votre mot de passe est expiré, merci de le changer.', {
          toastId: 'useLogin.TooEarly',
        });
      }
    },
    onSuccess: (response) => {
      toast.success('Bienvenue', {
        toastId: 'success1',
      });
    },
    ...config,
    mutationFn: login,
  });
};

export const logout = async ({}: {}): Promise<void> => {
  return client.post(`/auth/logout`, {});
};

type UseLogoutOptions = {
  config?: MutationConfig<typeof logout>;
};

export const useLogout = ({ config }: UseLogoutOptions = {}) => {
  return useMutation({
    onError: (apiError, __, context: any) => {
      if (HttpStatusCode.NotFound === apiError?.response?.status) {
        toast.error('Au revoir.', {
          toastId: 'useLogout.NotFound',
        });
      }
      if (HttpStatusCode.Forbidden === apiError?.response?.status) {
        toast.error('Au revoir.', {
          toastId: 'forbiddenToast',
        });
      }
      if (HttpStatusCode.InternalServerError === apiError?.response?.status) {
        toast.error('Au revoir.', {
          toastId: 'internalServerErrorToast',
        });
      }
      if (HttpStatusCode.Unauthorized === apiError?.response?.status) {
        toast.error('Au revoir.', {
          toastId: 'unauthorizeToast',
        });
      }
    },
    onSuccess: (response) => {
      localStorage.removeItem('token');
      history.replace('/login');
      toast.success('Au revoir', {
        toastId: 'useLogout.onSuccess',
      });
    },
    ...config,
    mutationFn: logout,
    mutationKey: ['logout'],
  });
};

export const renewPasswordQuery = async ({
  email,
}: {
  email: string;
}): Promise<void> => {
  const request: QueryRenewPasswordRequest = {
    email: email,
  };
  return await client.post(`/auth/queryrenewpassword`, request);
};

type UseRenewPasswordQueryOptions = {
  config?: MutationConfig<typeof renewPasswordQuery>;
};

export const useRenewPasswordQuery = ({
  config,
}: UseRenewPasswordQueryOptions = {}) => {
  return useMutation({
    onError: (apiError, __, context: any) => {
      if (apiError?.response?.status === 401)
        toast.error('Identifiants incorrectes');
    },
    onSuccess: (response) => {
      toast.success(
        "Envoie d'un email de changement de mot de passe, vérifier votre boite mail",
        {
          toastId: 'successRenewPasswordQuery',
        }
      );
    },
    ...config,
    mutationFn: renewPasswordQuery,
  });
};

export const renewPassword = async ({
  tokenValue,
  newPassword,
}: {
  tokenValue: string;
  newPassword: string;
}): Promise<void> => {
  const request: RenewPasswordRequest = {
    tokenValue: tokenValue,
    newPassword: newPassword,
  };
  return await client.post(`/auth/renewpassword`, request);
};

type UseRenewPasswordOptions = {
  config?: MutationConfig<typeof renewPassword>;
};

export const useRenewPassword = ({ config }: UseRenewPasswordOptions = {}) => {
  return useMutation({
    onError: (apiError, __, context: any) => {
      if (apiError?.response?.status === 401)
        toast.error('Identifiants incorrectes');
    },
    onSuccess: (response) => {
      toast.success('Changement de mot de passe réussi', {
        toastId: 'successRenewPassword',
      });
    },
    ...config,
    mutationFn: renewPassword,
  });
};
